import {
    CustomContent,
    ONBOARDING_STYLES,
    handleClose,
    renderNextButton,
    renderPreviousButton,
    steps,
} from '@/components/onboarding/onboarding.tsx'
import { ThemeProvider } from '@/lib/ThemeProvider.tsx'
import { TourProvider } from '@reactour/tour'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Navigate, RouterProvider, createRouter } from '@tanstack/react-router'
import { ReactFlowProvider } from '@xyflow/react'
// Import the generated route tree
import { routeTree } from './routeTree.gen'

// Import i18n configuration
import '../i18n.js'

// Create a new router instance
const router = createRouter({
    routeTree,
    defaultNotFoundComponent: () => <Navigate to="/" />,
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 120000,
        },
    },
})

export default function App() {
    return (
        <TourProvider
            steps={steps}
            styles={ONBOARDING_STYLES}
            components={{
                Content: CustomContent,
            }}
            onClickMask={() => {
                console.log('mask clicked')
            }} // Prevents the mask from closing the tour
            onClickClose={handleClose}
            prevButton={renderPreviousButton}
            nextButton={renderNextButton}
        >
            <ReactFlowProvider>
                <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </ThemeProvider>
            </ReactFlowProvider>
        </TourProvider>
    )
}
