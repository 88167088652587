import apiRequest from '@/lib/apiClient'
export interface GetTenantResponseV1 {
    id: string
}

export interface PostTenantRequestV1 {
    emailAddress: string
}

export interface GetTenantDetailsResponseV1 {
    id: string
    balance: number
    pools: PoolResponseV1[]
}

export interface PoolResponseV1 {
    id: string
    name: string
    balance: number
    maxBalance: number
    projects: string[]
}

export interface GetTenantBalanceResponseV1 {
    balance: number
}

export interface CreateBillingPortalRequestV1 {
    // note: this should be the current url that the browser is at, to redirect back to.
    returnUrl: string
}

export interface CreateBillingPortalResponseV1 {
    // this is a redirect url to stripe.
    url: string
}

export interface CreatePaymentRequestV1 {
    // note: this must be between 20 and 100
    quantity: number
    // note: this should be the current url that the browser is at, to redirect back to.
    callbackUrl: string
}

export interface CreatePaymentResponseV1 {
    // this is a redirect url to stripe.
    url: string
}

export interface PatchPoolRequestV1 {
    // The pool name. Optional.
    name?: string
    // This value is + or - from the current max balance. Optional.
    balanceUpdate?: number
    addedProjects: string[]
    removedProjects: string[]
}

export interface PostPoolRequestV1 {
    // Max length 75 characters.
    name: string
    // Max Balance
    /// must be greater than 1.0.
    maxBalance: number
    projects: string[]
}

export interface PostProjectResponseV1 {
    tenantId: string
    poolId: string
    projectId: string
}

enum TransactionType {
    Debit = 'Debit',
    Credit = 'Credit',
}

enum SourceType {
    None = 'None',
    Pipeline = 'Pipeline',
    Datasource = 'Datasource',
    Proxy = 'Proxy',
    NotApplicable = 'NotApplicable',
}

enum OperationType {
    None = 'None',
    OpenAiModelTextOutputInputToken = 'OpenAiModelTextOutputInputToken',
    OpenAiModelTextOutputOutputToken = 'OpenAiModelTextOutputOutputToken',
    AnthropicAiModelTextOutputInputToken = 'AnthropicAiModelTextOutputInputToken',
    AnthropicAiModelTextOutputOutputToken = 'AnthropicAiModelTextOutputOutputToken',
    ProxyExecution = 'ProxyExecution',
    PipelineExecution = 'PipelineExecution',
}

export interface GetTransactionsRequestFilterV1 {
    PoolId?: string
    ProjectId?: string
    Type?: TransactionType
    SourceType?: SourceType
    SourceId?: string
    OperationType?: OperationType
    StartTime?: number
    EndTime?: number
    Descending?: boolean
    Limit?: number
    Offset?: number
}

export interface GetTransactionsResponseV1 {
    createdAt: number
    tenantId: string
    poolId?: string
    projectId?: string
    transactionType: TransactionType
    sourceType: SourceType
    sourceId?: string
    executionId?: string
    transactionOperationType: OperationType
    rawValue: number
    creditValue: number
}

export const getTransactionsV1 = async (
    filter: GetTransactionsRequestFilterV1
): Promise<GetTransactionsResponseV1[]> => {
    const queryParams = new URLSearchParams({
        ...(filter.PoolId && { PoolId: filter.PoolId }),
        ...(filter.ProjectId && { ProjectId: filter.ProjectId }),
        ...(filter.Type && { Type: filter.Type }),
        ...(filter.SourceType && { SourceType: filter.SourceType }),
        ...(filter.SourceId && { SourceId: filter.SourceId }),
        ...(filter.OperationType && { OperationType: filter.OperationType }),
        ...(filter.StartTime !== undefined && { StartTime: filter.StartTime.toString() }),
        ...(filter.EndTime !== undefined && { EndTime: filter.EndTime.toString() }),
        ...(filter.Descending !== undefined && { Descending: filter.Descending.toString() }),
        ...(filter.Limit !== undefined && { Limit: filter.Limit.toString() }),
        ...(filter.Offset !== undefined && { Offset: filter.Offset.toString() }),
    }).toString()
    return apiRequest(`/marketplace/v1/transactions?${queryParams}`)
}

export const getTenantV1 = async (): Promise<GetTenantResponseV1 | null> => {
    return apiRequest(`/marketplace/v1/tenant`)
}

export const getTenantDetailsV1 = async (): Promise<GetTenantDetailsResponseV1 | null> => {
    return apiRequest(`/marketplace/v1/tenant/details`)
}

export const getTenantBalanceV1 = async (): Promise<GetTenantBalanceResponseV1 | null> => {
    return apiRequest(`/marketplace/v1/tenant/balance`)
}

export const createTenantV1 = async (request: PostTenantRequestV1): Promise<boolean> => {
    return apiRequest(`/marketplace/v1/tenant`, { method: 'POST', body: request })
}

export const createBillingPortalV1 = async (
    request: CreateBillingPortalRequestV1
): Promise<CreateBillingPortalResponseV1> => {
    return apiRequest(`/marketplace/v1/billingportal`, { method: 'POST', body: request })
}

export const createPaymentPortalV1 = async (
    request: CreatePaymentRequestV1
): Promise<CreatePaymentResponseV1> => {
    return apiRequest(`/marketplace/v1/payment`, { method: 'POST', body: request })
}

export const createPoolV1 = async (request: PostPoolRequestV1): Promise<PoolResponseV1> => {
    return apiRequest(`/marketplace/v1/pool`, { method: 'POST', body: request })
}

export const UpdatePoolV1 = async (
    poolId: string,
    request: PatchPoolRequestV1
): Promise<PoolResponseV1> => {
    return apiRequest(`/marketplace/v1/pool/${poolId}`, { method: 'PATCH', body: request })
}

export const DeletePoolV1 = async (poolId: string): Promise<boolean> => {
    return apiRequest(`/marketplace/v1/pool/${poolId}`, { method: 'DELETE' })
}

export const createProjectMappingV1 = async (
    poolId: string,
    projectId: string
): Promise<PostProjectResponseV1> => {
    return apiRequest(`/marketplace/v1/pool/${poolId}/${projectId}`, { method: 'POST' })
}

export const deleteProjectMappingV1 = async (
    poolId: string,
    projectId: string
): Promise<boolean> => {
    return apiRequest(`/marketplace/v1/pool/${poolId}/${projectId}`, { method: 'DELETE' })
}
