import {
    AiOperationsExecution,
    ConsumptionFilterOptions,
    getAllExecutions,
} from '@/api/aiOperationsExecution.ts'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { endOfMonth, isAfter, isWithinInterval, startOfMonth, sub } from 'date-fns'
import { getAllProjects } from '@/api/projects.ts'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import { Link } from '@tanstack/react-router'
import PaginationHeader from '@/components/pagination/paginationHeader.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog.tsx'
import ConsumptionFilters from '@/components/home/consumption/ConsumptionFilters.tsx'
import ExportForm from '@/components/home/consumption/ExportForm.tsx'

const Consumption: React.FC = () => {
    const [filter, setFilter] = useState<string>('')
    const [consumptionFilter, setConsumptionFilter] = useState<ConsumptionFilterOptions>({})
    const [executionOperations, setExecutionOperations] = useState<AiOperationsExecution[]>([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogType, setDialogType] = useState<'export' | 'filter'>('filter')
    const [filterCount, setFilterCount] = useState<number>(0)
    const operationsQuery = useQuery({
        queryKey: [QueryKeys.ALL_OPERATIONS_EXECUTIONS],
        queryFn: () => getAllExecutions(),
    })
    const projectsQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    useEffect(() => {
        if (operationsQuery.data) {
            setExecutionOperations(operationsQuery.data)
        }
    }, [operationsQuery.data])

    useEffect(() => {
        setFilterCount(0)
        Object.keys(consumptionFilter).forEach((k) => {
            // do not add default state to filter count
            if (consumptionFilter[k as keyof ConsumptionFilterOptions]) {
                setFilterCount((prev) => prev + 1)
            }
        })
    }, [consumptionFilter])

    const columns: ColumnDef<AiOperationsExecution>[] = [
        {
            accessorKey: 'executionDateTime',
            header: 'Timestamp',
            cell: ({ row }) => {
                const date = new Date(row.original.executionDateTime)
                return (
                    <span>{`${date.toLocaleDateString(undefined, { dateStyle: 'short' })} at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`}</span>
                )
            },
        },
        {
            accessorKey: 'modelName',
            header: 'Model',
        },
        {
            accessorKey: 'executionSourceType',
            header: 'Source',
        },
        {
            accessorKey: 'pipelineId',
            header: 'Pipeline',
            cell: ({ row }) => (
                <Link
                    to={'/$projectId/pipelines/$pipelineId/$versionNumber'}
                    params={{
                        projectId: row.original.projectId,
                        pipelineId: row.original.pipelineId,
                        versionNumber: row.original.pipelineVersion,
                    }}
                    className={'text-primary hover:underline'}
                >
                    {row.original.pipelineName !== ''
                        ? row.original.pipelineName
                        : row.original.pipelineId}
                </Link>
            ),
        },
        {
            accessorKey: 'projectId',
            header: 'Project',
            cell: ({ row }) => {
                const project = projectsQuery.data?.find(
                    (project) => project.id === row.original.projectId
                )
                return (
                    <div className={'flex gap-1 items-center'}>
                        {project?.projectIcon ? (
                            <img
                                src={project.projectIcon}
                                alt={project.name}
                                height={17}
                                width={17}
                            />
                        ) : (
                            <AiriaIcon className="w-[17px] h-[17px]" />
                        )}
                        {project?.name}
                    </div>
                )
            },
        },
        {
            accessorKey: 'executionUser',
            header: 'Project Owner',
        },
        {
            header: 'Total Tokens $',
            cell: ({ row }) => {
                return (
                    <p>
                        $
                        {(
                            row.original.inputTokenAmountConsumed +
                            row.original.outputTokenAmountConsumed
                        ).toFixed(2)}
                    </p>
                )
            },
        },
        {
            accessorKey: 'inputTokenAmountConsumed',
            header: 'Input Tokens $',
            cell: ({ row }) => {
                return <p>${row.original.inputTokenAmountConsumed.toFixed(2)}</p>
            },
        },
        {
            accessorKey: 'outputTokenAmountConsumed',
            header: 'Output Tokens $',
            cell: ({ row }) => <p>${row.original.outputTokenAmountConsumed.toFixed(2)}</p>,
        },
        {
            header: 'Total Tokens Consumed',
            cell: ({ row }) => (
                <span>
                    {row.original.inputTokenCountConsumed + row.original.outputTokenCountConsumed}
                </span>
            ),
        },
        {
            accessorKey: 'inputTokenCountConsumed',
            header: 'Input Tokens Consumed',
        },
        {
            accessorKey: 'outputTokenCountConsumed',
            header: 'Output Tokens Consumed',
        },
        {
            accessorKey: 'balanceUsed',
            header: 'Balanced Used',
            cell: ({ row }) => {
                return <p>${row.original.balanceUsed.toFixed(2)}</p>
            },
        },
        {
            accessorKey: 'executionId',
            header: 'Execution Id',
        },
    ]

    const onOpenDialog = (type: 'export' | 'filter', open: boolean) => {
        setDialogType(type)
        setDialogOpen(open)
    }

    const resetActiveFilters = () => {
        setConsumptionFilter({
            projectId: undefined,
            executionDate: undefined,
        })
        setExecutionOperations(operationsQuery.data ?? [])
    }

    const onFilterChange = (filter: ConsumptionFilterOptions) => {
        resetActiveFilters()
        setConsumptionFilter(filter)
        if (filter.projectId) {
            onProjectIdFilter(filter.projectId)
        }
        if (filter.executionDate) {
            onExecutionDateFilter(filter.executionDate)
        }
    }
    const onProjectIdFilter = (projectId?: string) => {
        if (projectId) {
            setExecutionOperations(
                (prev) => prev.filter((operation) => operation.projectId === projectId) ?? []
            )
        } else {
            setExecutionOperations(operationsQuery.data ?? [])
        }
    }

    const onExecutionDateFilter = (
        executionDateStr?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
    ) => {
        if (executionDateStr === 'thisMonth') {
            const now = new Date()
            const monthStart = startOfMonth(now)
            setExecutionOperations(
                (prev) =>
                    prev.filter((operation) =>
                        isAfter(new Date(operation.executionDateTime), monthStart)
                    ) ?? []
            )
        } else if (executionDateStr === 'lastMonth') {
            const now = new Date()
            const lastMonthStart = startOfMonth(sub(now, { months: 1 }))
            const lastMonthEnd = endOfMonth(lastMonthStart)

            setExecutionOperations(
                (prev) =>
                    prev.filter((operation) =>
                        isWithinInterval(new Date(operation.executionDateTime), {
                            start: lastMonthStart,
                            end: lastMonthEnd,
                        })
                    ) ?? []
            )
        } else if (executionDateStr === 'last90Days') {
            const ninetyDaysAgo = sub(new Date(), { days: 90 })
            setExecutionOperations(
                (prev) =>
                    prev.filter((operation) =>
                        isAfter(new Date(operation.executionDateTime), ninetyDaysAgo)
                    ) ?? []
            )
        } else {
            setExecutionOperations(operationsQuery.data ?? [])
        }
    }

    return (
        <div className={'flex flex-col gap-2 mt-6'}>
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2">
                    <PaginationHeader
                        filter={filter}
                        setFilter={setFilter}
                        sortOptions={columns}
                        queryParams={{
                            projectId: undefined,
                            pageNumber: 0,
                            pageSize: 0,
                            sortBy: undefined,
                            sortDirection: undefined,
                        }}
                    />
                </div>
                <div className={'flex gap-4'}>
                    <Button
                        onClick={() => onOpenDialog('filter', true)}
                        className={`${filterCount > 0 ? 'bg-primary' : 'text-primary bg-[#EAF4FF] hover:bg-[#EAF4FFBB] hover:text-primary'} flex items-center gap-1 font-gilroy-bold rounded-sm h-8 shadow-none`}
                    >
                        Filters
                        {filterCount > 0 && (
                            <span
                                className={
                                    'h-5 w-5 flex justify-center bg-primary-foreground rounded-full text-primary px-2'
                                }
                            >
                                {filterCount}
                            </span>
                        )}
                    </Button>
                    <Button
                        onClick={() => {
                            onOpenDialog('export', true)
                        }}
                        type="button"
                        className="text-primary font-gilroy-bold text-[13px] rounded-sm h-8 bg-[#EAF4FF] shadow-none hover:bg-[#EAF4FFBB] hover:text-primary"
                    >
                        Export
                    </Button>
                </div>
            </div>
            <DefaultDataTable
                className="h-[calc(100vh-250px)]"
                pagination
                totalCount={executionOperations.length}
                rowsPerPage={25}
                columns={columns}
                data={executionOperations}
            />
            <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogContent className={'min-w-[850px]'}>
                    <DialogHeader>
                        {dialogType === 'filter' ? (
                            <div className={'flex gap-2'}>
                                <span className={'text-2xl'}>Filters</span>
                                {filterCount > 0 && (
                                    <div
                                        className={
                                            'rounded-full bg-primary text-primary-foreground text-xs px-3 py-2'
                                        }
                                    >
                                        {filterCount}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>Export</div>
                        )}
                    </DialogHeader>
                    {dialogType === 'filter' ? (
                        <ConsumptionFilters
                            filter={consumptionFilter}
                            onFilterChange={onFilterChange}
                            onClearFilters={resetActiveFilters}
                            onClose={() => setDialogOpen(false)}
                        />
                    ) : (
                        <ExportForm currentWindow={consumptionFilter.executionDate} />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Consumption
