import Container from '@/assets/icons/container.svg?react'
import Dataflow from '@/assets/icons/dataflow.svg?react'
import SIEM from '@/components/settings/siem.tsx'
import SSO from '@/components/settings/sso.tsx'
import { TabsContent } from '@/components/ui/tabs.tsx'
import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_mainLayout/settings')({
    component: () => {
        return <Settings key={`settings`} />
    },
})

function Settings() {
    return (
        <div className="flex flex-col gap-4 px-10">
            <Tabs className="flex flex-col" defaultValue="siem">
                <TabsList className="shrink-0 flex border-b border-mauve6 w-full gap-10">
                    <TabsTrigger
                        value="siem"
                        className={
                            'bg-background px-1 h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary'
                        }
                    >
                        <Dataflow className="w-6 h-6 mr-2 -ml-1" />
                        SIEM
                    </TabsTrigger>
                    <TabsTrigger
                        className={
                            'bg-background px-1 h-[45px] w-fit flex items-center justify-center text-[15px] leading-none select-none first:rounded-tl-md last:rounded-tr-md hover:text-violet11 data-[state=active]:text-violet11 data-[state=active]:shadow-[inset_0_-1px_0_0,0_1px_0_0] data-[state=active]:focus:relative data-[state=active]:focus:shadow-primary data-[state=active]:shadow-primary outline-none cursor-default data-[state=active]:text-primary'
                        }
                        value="sso"
                    >
                        <Container className="w-6 h-6 mr-2 -ml-1" />
                        SSO
                    </TabsTrigger>
                </TabsList>
                <TabsContent value={'siem'}>
                    <SIEM />
                </TabsContent>
                <TabsContent value={'sso'}>
                    <SSO />
                </TabsContent>
            </Tabs>
        </div>
    )
}
