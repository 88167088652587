import { Prompt, deletePrompt, getAllPrompts } from '@/api/prompts'
import { DefaultDataTable } from '@/components/DefaultDataTable'
import PaginationHeader, {
    paginationAndSortingParams,
} from '@/components/pagination/paginationHeader'
import { Button } from '@/components/ui/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { usePagination } from '@/hooks/use-pagination'
import useSearchEffect from '@/hooks/use-search-effect'
import { useSorting } from '@/hooks/use-sorting'
import { HandleDeleteError } from '@/lib/HandleErrors'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    Link,
    Outlet,
    SearchSchemaInput,
    createFileRoute,
    useNavigate,
    useRouter,
} from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { useDebounce } from '@uidotdev/usehooks'
import { MoreHorizontal, Pencil, Trash } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_mainLayout/$projectId/_projectLayout/prompts')({
    component: AllPrompts,
    validateSearch: (
        search: Record<string, unknown> & SearchSchemaInput
    ): paginationAndSortingParams => {
        return {
            projectId: search.projectId as string,
            pageNumber: search.pageNumber as number,
            pageSize: search.pageSize as number,
            sortBy: search.sortBy as string,
        }
    },
})

function AllPrompts() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const router = useRouter()
    const params = Route.useParams()
    const queryParams = Route.useSearch()
    const { limit, onPaginationChange, paginationState } = usePagination({
        initialPageIndex: queryParams.pageNumber ? queryParams.pageNumber - 1 : 0,
        initialPageSize: queryParams.pageSize,
    })
    const { sortingState, onSortingChange, field, order } = useSorting({
        initialField: queryParams.sortBy,
        initialOrder: queryParams.sortDirection ?? 'DESC',
    })

    const GetAllPrompts = useQuery({
        queryKey: [QueryKeys.ALL_PROMPTS, params.projectId, queryParams],
        queryFn: () =>
            getAllPrompts(
                queryParams.pageSize && queryParams.pageNumber
                    ? { ...queryParams, projectId: params.projectId }
                    : { pageSize: 25, pageNumber: 1, projectId: params.projectId }
            ),
    })

    const [searchValue, setSearchValue] = useState<string>('')
    const debouncedSearchValue = useDebounce(searchValue, 500)
    useSearchEffect({
        debouncedSearchValue,
        navigate,
        queryParams,
        params,
        onPaginationChange,
    })

    const queryClient = useQueryClient()
    const deleteMutation = useMutation({
        mutationFn: deletePrompt,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.ALL_PROMPTS, params.projectId],
            })
            toast({
                title: t('success_title'),
                description: t('delete_prompt_success'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })

    const handleRowClick = (row: { original: Prompt }) => {
        navigate({
            to: '/$projectId/prompts/$promptId/edit/$versionNumber',
            params: {
                promptId: row.original.id,
                versionNumber: row?.original?.activeVersion?.versionNumber?.toString() ?? '',
                projectId: params.projectId,
            },
            search: { ...queryParams },
        })
    }

    const handleDeletePrompt = async (id: string) => {
        if (window.confirm(t('delete_prompt_confirmation'))) {
            try {
                await deleteMutation.mutateAsync(id)
                router.invalidate()
            } catch (error) {
                HandleDeleteError(error, 'prompt')
            }
        }
    }

    useEffect(() => {
        navigate({
            search: {
                ...queryParams,
                pageNumber: paginationState.pageIndex + 1,
                pageSize: limit,
                sortBy: field,
                sortDirection: order,
            },
        })
    }, [paginationState, sortingState, router.history.location])

    return (
        <div className="flex flex-col gap-2 pr-2">
            <div className="flex justify-between items-center">
                <div className={'flex gap-2 items-center'}>
                    <PaginationHeader
                        queryParams={queryParams}
                        filter={searchValue}
                        setFilter={setSearchValue}
                        sortOptions={columns(handleDeletePrompt, t)}
                    />
                </div>
                <div className="flex gap-2">
                    <Link
                        to="/$projectId/prompts/workbench"
                        params={{ projectId: params.projectId }}
                        search={{ ...queryParams }}
                    >
                        <Button variant={'secondary'}>{t('workbench')}</Button>
                    </Link>
                    <Link
                        to="/$projectId/prompts/new"
                        params={{ projectId: params.projectId }}
                        search={{ ...queryParams }}
                    >
                        <Button>{t('new_prompt')}</Button>
                    </Link>
                </div>
            </div>
            <DefaultDataTable
                className="h-[calc(100vh-290px)]"
                data={GetAllPrompts?.data?.items ?? []}
                columns={columns(handleDeletePrompt, t)}
                onRowClick={handleRowClick}
                serverPagination
                totalCount={GetAllPrompts.data?.totalCount}
                limit={limit}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                paginationState={paginationState}
                sortingState={sortingState}
            />
            <Outlet />
        </div>
    )
}

export const columns = (
    handleDeletePrompt: (id: string) => void,
    t: (key: string) => string
): ColumnDef<Prompt>[] => [
    {
        accessorKey: 'name',
        header: t('name'),
    },
    {
        accessorKey: 'activeVersion.versionNumber',
        header: t('active_version'),
    },
    {
        accessorFn: (row) => row.versions?.find((v) => v.isLatest)?.versionNumber,
        header: t('latest_version'),
    },
    {
        accessorKey: 'updatedAt',
        header: t('last_modified'),
        cell: ({ row }) => {
            const date = new Date(row.original.updatedAt)
            return <p>{isNaN(date.getTime()) ? t('unknown_date') : date.toLocaleString()}</p>
        },
    },
    {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only fixed">{t('open_menu')}</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel className="flex gap-1">{t('actions')}</DropdownMenuLabel>
                        <DropdownMenuItem>
                            <Link
                                to={`/$projectId/prompts/${row.original.id}/edit/${row?.original?.activeVersion?.versionNumber ?? ''}`}
                                className="flex gap-1"
                            >
                                <Pencil className="h-4 w-4" />
                                {t('edit_prompt')}
                            </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            className="flex gap-1 text-red-500"
                            onClick={() => handleDeletePrompt(row.original.id)}
                        >
                            <Trash className="h-4 w-4" />
                            {t('delete_prompt')}
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            )
        },
    },
]
