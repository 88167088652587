import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import { getAllProjects } from '@/api/projects.ts'
import { Badge } from '@/components/ui/badge.tsx'
import AiriaIcon from '@/assets/icons/airia-icon.svg?react'
import { Separator } from '@/components/ui/separator.tsx'
import { Button } from '@/components/ui/button.tsx'
import { ConsumptionFilterOptions } from '@/api/aiOperationsExecution.ts'

export interface ConsumptionFiltersProps {
    filter: ConsumptionFilterOptions
    onFilterChange: (filter: ConsumptionFilterOptions) => void
    onClose: () => void
    onClearFilters: () => void
}

const ConsumptionFilters: React.FC<ConsumptionFiltersProps> = (props) => {
    const [filter, setFilter] = useState<ConsumptionFilterOptions>(props.filter)
    const projectQuery = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })

    const onProjectFilter = (projectId?: string) => {
        if (projectId === filter.projectId) {
            setFilter((prev) => ({ ...prev, projectId: undefined }))
        } else {
            setFilter((prev) => ({
                ...prev,
                projectId,
            }))
        }
    }

    const onExecutionDateFilter = (
        executionDate?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
    ) => {
        if (executionDate === filter.executionDate) {
            setFilter((prev) => ({ ...prev, executionDate: undefined }))
        } else {
            setFilter((prev) => ({ ...prev, executionDate }))
        }
    }

    const onApplyFilter = () => {
        props.onFilterChange(filter)
        props.onClose()
    }

    const clearFilters = () => {
        setFilter({
            projectId: undefined,
            executionDate: undefined,
        })
        props.onClearFilters()
        props.onClose()
    }

    return (
        <div className={'flex flex-col gap-2'}>
            <div className={'flex flex-col gap-2 p-4'}>
                <span>Project</span>
                <div className={'group flex flex-wrap gap-2'}>
                    {projectQuery.data &&
                        projectQuery.data.map((project) => (
                            <Badge
                                variant={filter.projectId === project.id ? 'default' : 'outline'}
                                onClick={() => onProjectFilter(project.id)}
                                className={'cursor-pointer px-3 py-2 rounded-full'}
                            >
                                {project.projectIcon ? (
                                    <img
                                        src={project.projectIcon}
                                        alt={project.name}
                                        height={16}
                                        width={16}
                                    />
                                ) : (
                                    <AiriaIcon className="w-4 h-4" />
                                )}
                                {project.name}
                            </Badge>
                        ))}
                </div>
            </div>
            <div className={'flex flex-col gap-2 p-4'}>
                <span>Execution Date</span>
                <div className={'group flex flex-wrap gap-2'}>
                    <Badge
                        variant={filter.executionDate === 'thisMonth' ? 'default' : 'outline'}
                        onClick={() => onExecutionDateFilter('thisMonth')}
                        className={'cursor-pointer px-3 py-2 rounded-full'}
                    >
                        Current Month
                    </Badge>
                    <Badge
                        variant={filter.executionDate === 'lastMonth' ? 'default' : 'outline'}
                        onClick={() => onExecutionDateFilter('lastMonth')}
                        className={'cursor-pointer px-3 py-2 rounded-full'}
                    >
                        Previous Month
                    </Badge>
                    <Badge
                        variant={filter.executionDate === 'last90Days' ? 'default' : 'outline'}
                        onClick={() => onExecutionDateFilter('last90Days')}
                        className={'cursor-pointer px-3 py-2 rounded-full'}
                    >
                        Last 90 Days
                    </Badge>
                </div>
            </div>
            <Separator />
            <div className={'flex justify-end gap-6'}>
                <Button variant={'secondary'} onClick={() => clearFilters()}>
                    Clear all
                </Button>
                <Button onClick={() => onApplyFilter()}>Apply</Button>
            </div>
        </div>
    )
}

export default ConsumptionFilters
