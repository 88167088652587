import { UseCase } from '@/components/tools/UseCase'
import apiRequest from '@/lib/apiClient'

export type Parameter = {
    id: string
    name: string
    type: string
    description: string
    default: string
    validOptions: string[]
    toolDefinitionId: string
}

export type Tool = {
    id: string
    name: string
    description: string
    methodType: string
    purpose: string
    useCases: UseCase[]
    headers: { key: string; value: string }[]
    parameters: Parameter[]
    apiEndpoint: string
    toolsHandler: string
    createdAt: string
    updatedAt: string
    projectId: string
}

export const CreateTool = async (tool: Tool): Promise<void> => {
    return apiRequest(`/Tools`, { method: 'POST', body: tool })
}

export const DeleteTool = async (id: string): Promise<void> => {
    return apiRequest(`/Tools/${id}`, { method: 'DELETE' })
}

export const getAllTools = async (): Promise<Tool[]> => {
    return apiRequest('/Tools')
}

export const getToolById = async (toolId: string): Promise<Tool> => {
    return apiRequest(`/Tools/${toolId}`)
}

export const updateTool = async (tool: Tool): Promise<void> => {
    return apiRequest(`/Tools/${tool.id}`, { method: 'PUT', body: tool })
}
