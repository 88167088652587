import { DeletePoolV1, PoolResponseV1 } from '@/api/marketplace.ts'
import { getAllProjects } from '@/api/projects.ts'
import { DefaultDataTable } from '@/components/DefaultDataTable.tsx'
import { Badge } from '@/components/ui/badge.tsx'
import { Button } from '@/components/ui/button.tsx'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { Progress } from '@/components/ui/progress.tsx'
import { toast } from '@/components/ui/use-toast.ts'
import { QueryKeys } from '@/constants/QueryKeys.ts'
import useTenantQuery from '@/hooks/use-tenant-query.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Link, createFileRoute, useRouter } from '@tanstack/react-router'
import { ColumnDef } from '@tanstack/react-table'
import { ArrowLeft, Key, Loader, MoreHorizontal } from 'lucide-react'
import millify from 'millify'
import { useTranslation } from 'react-i18next'

export const Route = createFileRoute('/_mainLayout/billing/pools')({
    component: Pools,
})

function Pools() {
    const { t } = useTranslation()
    const router = useRouter()
    const queryClient = useQueryClient()
    const { data: projects } = useQuery({
        queryKey: [QueryKeys.PROJECTS],
        queryFn: () => getAllProjects(),
    })
    const deleteMutation = useMutation({
        mutationFn: DeletePoolV1,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.TENANT_DETAILS] })
            toast({
                title: t('success_title'),
                description: t('credits.delete_success_description'),
            })
        },
        onError: () => {
            toast({
                title: t('error_title'),
                description: t('error_description_generic'),
                variant: 'destructive',
            })
        },
    })
    const { details, isLoading } = useTenantQuery()

    const onManageRowClick = (id: string) => {
        router.navigate({
            to: `/billing/$poolId/edit`,
            params: { projectId: null, poolId: id },
        })
    }

    const onDeletePoolClick = async (id: string) => {
        try {
            await deleteMutation.mutateAsync(id)
        } catch (err) {
            console.error('error deleting pool', err)
        }
    }

    const handleOnRowClick = (row: { original: PoolResponseV1 }) => {
        router.navigate({
            to: `/billing/$poolId`,
            params: { projectId: null, poolId: row.original.id },
        })
    }

    const onCreatePoolClick = () => {
        router.navigate({ to: '/billing/new' })
    }

    const columns: ColumnDef<PoolResponseV1>[] = [
        {
            accessorKey: 'name',
            header: t('name'),
        },
        {
            accessorKey: 'usage',
            header: t('credits_activity.usage'),
            enableSorting: false,
            cell: ({ row }) => (
                <div>
                    <Progress
                        className={
                            !row.original.maxBalance
                                ? 'bg-gradient-to-r from-primary to-secondary'
                                : ''
                        }
                        value={
                            row.original.maxBalance
                                ? ((row.original.maxBalance - row.original.balance) /
                                      row.original.maxBalance) *
                                  100
                                : 0
                        }
                    />
                    <span>
                        {(row.original.maxBalance - row.original.balance).toFixed(3)} of{' '}
                        {row.original.maxBalance ? millify(row.original.maxBalance) : 'Infinity'}
                    </span>
                </div>
            ),
        },
        {
            accessorKey: 'project',
            header: t('credits_activity.projects'),
            enableSorting: false,
            cell: ({ row }) => (
                <div>
                    {projects
                        ?.filter((p) => row.original.projects.includes(p.id))
                        .map((project) => (
                            <Badge className={'rounded-full bg-primary/20 text-foreground'}>
                                {project.name}
                            </Badge>
                        ))}
                </div>
            ),
        },
        {
            id: 'actions',
            enableHiding: false,
            cell: ({ row }) => {
                return (
                    <DropdownMenu key={`${row.original.id}-actions`}>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                                <span className="sr-only fixed">{t('open_menu')}</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuItem onClick={() => onManageRowClick(row.original.id)}>
                                {t('credits.manage_pool')}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="flex gap-1 text-red-500"
                                onClick={() => onDeletePoolClick(row.original.id)}
                            >
                                {t('credits.delete_pool')}
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    return (
        <div>
            <div className={'mb-8'}>
                <Link
                    to="/billing"
                    className="flex flex-row gap-2 items-center text-accent-foreground"
                >
                    <ArrowLeft size="18" />
                    <p>{t('credits_activity.back')}</p>
                </Link>
            </div>
            <div className={'flex justify-between'}>
                <h1 className={'text-4xl'}>{t('credits.pools')}</h1>
                <Button className={'text-primary-foreground'} onClick={onCreatePoolClick}>
                    {t('credits.create_pool')}
                </Button>
            </div>
            {!details?.pools || details.pools.length <= 0 ? (
                <div
                    className={
                        'flex flex-col justify-center items-center dark:bg-[#1C1C1B] bg-[#cbcbcb] dark:text-[#ECEED7] h-[180px] rounded-lg'
                    }
                >
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <Key />
                            <h2>{t('credits.no_pools')}</h2>
                            <p>{t('credits.no_pools_subheader')}</p>
                        </>
                    )}
                </div>
            ) : (
                <div>
                    <DefaultDataTable
                        columns={columns}
                        data={details.pools}
                        onRowClick={handleOnRowClick}
                    />
                </div>
            )}
        </div>
    )
}
