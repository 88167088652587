import { CreateModel, Model } from '@/api/models.ts'
import apiRequest from '@/lib/apiClient'
export interface LibraryModel {
    credentialsId: string
    id: string
    category: string
    description: string
    downloads: number
    type: string
    languages: string[]
    licenseType?: string
    name: string
    price: string
    inputTokenPrice: number
    outputTokenPrice: number
    promptId: string
    url: string
    provider: string
    rating: number
    tags: string[]
}

export const LIBRARY_PROVIDERS = ['Ollama', 'OpenAI', 'Anthropic']
export const LIBRARY_LANGUAGES = ['English', 'Spanish', 'French', 'Chinese', 'German']
export const LIBRARY_CHIP_FILTERS = [
    'Popular',
    'Self Hosted',
    'By Airia',
    'Image',
    'Text',
    'Video',
    'Audio',
]
export const LIBRARY_MODELS: LibraryModel[] = [
    {
        credentialsId: '00000000-0000-0000-0000-000000000000',
        id: '577d4dd1-b798-434d-8cec-5a62698500dd',
        category: 'Multimodal',
        description:
            'GPT-4o mini is our most cost-efficient small model that’s smarter and cheaper than GPT-3.5 Turbo, and has vision capabilities. The model has 128K context and an October 2023 knowledge cutoff.',
        downloads: 0,
        type: 'Text',
        languages: ['English'],
        name: 'gpt-4o-mini',
        price: 'Pricing from OpenAI models available at: https://openai.com/api/pricing/',
        inputTokenPrice: 0.15,
        outputTokenPrice: 0.6,
        promptId: '00000000-0000-0000-0000-000000000000',
        url: 'https://platform.openai.com/docs/models/gpt-4o-mini',
        provider: 'OpenAI',
        rating: 0,
        tags: ['chat', 'gpt', '4o', 'mini', 'openai'],
    },
    {
        credentialsId: '00000000-0000-0000-0000-000000000000',
        id: '5a3055c5-095b-4c91-89cf-063616297718',
        category: 'Multimodal',
        description:
            'GPT-4o is our most advanced multimodal model that’s faster and cheaper than GPT-4 Turbo with stronger vision capabilities. The model has 128K context and an October 2023 knowledge cutoff.',
        downloads: 0,
        type: 'Text',
        languages: ['English'],
        name: 'gpt-4o',
        price: 'Pricing from OpenAI models available at: https://openai.com/api/pricing/',
        inputTokenPrice: 5,
        outputTokenPrice: 15,
        promptId: '00000000-0000-0000-0000-000000000000',
        url: 'https://platform.openai.com/docs/models/gpt-4o',
        provider: 'OpenAI',
        rating: 0,
        tags: ['chat', 'gpt', '4o', 'openai'],
    },
]

export const MODEL_DESCRIPTION = `
### Model Description
IMAGE TO TEXT TRANSLATE is a transformers model pretrained on a large corpus of English data in a self-supervised fashion. This means it was pretrained on the raw texts only, with no humans labelling them in any way (which is why it can use lots of publicly available data) with an automatic process to generate
## Training Procedure
### Preprocessing
The texts are lowercased and tokenized using WordPiece and a vocabulary size of 30,000. The inputs of the model are then of the form:
\`\`\`python
python -m torch.distributed.launch --nproc_per_node=8 ./examples/question-answering/run_qa.py \\
    --model_name_or_path bert-large-uncased-whole-word-masking \\
    --dataset_name squad \\
    --do_train \\
    --do_eval \\
    --learning_rate 3e-5 \\
    --num_train_epochs 2 \\
    --max_seq_length 384 \\
    --doc_stride 128 \\
    --output_dir ./examples/models/wwm_uncased_finetuned_squad/ \\
    --per_device_eval_batch_size=3   \\
    --per_device_train_batch_size=3   \\
\`\`\`
IMAGE TO TEXT TRANSLATE is a transformers model pretrained on a large corpus of English data in a self-supervised fashion. This means it was pretrained on the raw texts only, with no humans labelling them in any way (which is why it can use lots of publicly available data) with an automatic process to generate...
`

export const getAllLibraryModels = async (): Promise<LibraryModel[]> => {
    try {
        const res = await apiRequest<{ models: LibraryModel[] }>('/marketplace/v1/Library')
        return res.models
    } catch {
        return LIBRARY_MODELS
    }
}

export const createModelWithinProject = async (model: LibraryModel, projectId: string) => {
    try {
        const provisionedModel: Omit<Model, 'id' | 'last_modified' | 'credentials'> = {
            displayName: model.name,
            modelName: model.name,
            sourceType: 'Library',
            provider: model.provider,
            type: model.type,
            projectId,
            userProvidedDetails: {
                url: model.url,
                deploymentType: 'External',
            },
        }
        return await CreateModel(provisionedModel)
    } catch (err) {
        console.error('Error creating model within project', err)
    }
}
