import { getApiUrl } from './utils'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

interface RequestOptions<TBody = unknown> {
    method?: HttpMethod
    body?: TBody
    queryParams?: Record<string, string | number>
    isFormData?: boolean
    headers?: Record<string, string>
}

async function apiRequest<TResponse, TBody = unknown>(
    endpoint: string,
    options: RequestOptions<TBody> = {}
): Promise<TResponse> {
    const {
        method = 'GET',
        body,
        queryParams,
        isFormData = false,
        headers: customHeaders = {},
    } = options
    const baseUrl = getApiUrl()
    let url = `${baseUrl}${endpoint}`

    if (queryParams) {
        const params = new URLSearchParams(queryParams as Record<string, string>)
        url += `?${params.toString()}`
    }

    const headers: HeadersInit = {
        ...customHeaders,
    }

    if (!isFormData && !headers['Content-Type']) {
        headers['Content-Type'] = 'application/json'
    }

    const config: RequestInit = {
        method,
        headers,
        credentials: 'include',
    }

    if (body !== undefined) {
        config.body = isFormData ? (body as BodyInit) : JSON.stringify(body)
    }

    const response = await fetch(url, config)

    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        throw {
            status: response.status,
            message: errorData.message || response.statusText,
        }
    }

    if (response.status === 204 || method === 'DELETE') {
        return {} as TResponse
    }

    if (headers['Content-Type'] === 'application/json') {
        return response.json()
    } else {
        return response.text() as TResponse
    }
}

export default apiRequest
