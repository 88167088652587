import { OnboardingSelectDialog } from '@/components/onboarding/onboarding'
import { AuthProvider, useAuth } from '@/hooks/use-auth'
import { useOnboarding } from '@/hooks/use-onboarding'
import { isApiError } from '@/lib/HandleErrors'
import { Outlet, createRootRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

export const Route = createRootRoute({
    component: Root,
    onError: (err) => {
        console.log('err bubbled up to root')
        console.log(err)
    },
    errorComponent: ({ error }: { error: unknown }) => {
        const { t } = useTranslation()
        if (isApiError(error)) {
            if (error.message == '401' || error.status == 401) {
                window.location.href = `/auth/logon?ReturnUrl=${encodeURIComponent(window.location.href)}`
                return <></>
            }
        }
        return <>{t('error_occurred')}</>
    },
})

export function Root() {
    const { onboarding, handleOnboardingSelection } = useOnboarding()

    return (
        <AuthProvider>
            <AuthenticatedRoot />
            <OnboardingSelectDialog
                open={onboarding.selector}
                onSelectionChange={handleOnboardingSelection}
            />
        </AuthProvider>
    )
}

function AuthenticatedRoot() {
    const { isLoading } = useAuth()

    return <>{!isLoading && <Outlet />}</>
}
