import { Connector } from '@/api/connectors.ts'
import Plus from '@/assets/icons/plus.svg?react'
import { Badge } from '@/components/ui/badge.tsx'

interface ConnectorCardProps {
    connector: Connector
    onCardClick?: () => void
}

export function ConnectorCard({ connector, onCardClick }: ConnectorCardProps) {
    return (
        <div
            className={
                'p-3 rounded-lg flex flex-1 flex-col gap-3 cursor-pointer border hover:border-primary group'
            }
            onClick={() => onCardClick && onCardClick()}
        >
            <div className={'flex gap-2 items-center justify-between'}>
                <h3 className={'text-lg'}>{connector.name}</h3>
                {connector.icon ? (
                    <img src={connector.icon} alt={connector.name} className={'w-8 h-8'} />
                ) : (
                    connector.iconComponent && <connector.iconComponent />
                )}
            </div>
            <p className={'text-background-foreground'}>{connector.description}</p>
            <div className={'flex flex-col justify-end h-[100%]'}>
                <div className={'flex justify-between items-center'}>
                    <Badge
                        className={
                            'bg-card text-card-foreground rounded-full shadow-none hover:bg-card hover:text-card-foreground'
                        }
                    >
                        <span className={'text-base'}>{connector.classification}</span>
                    </Badge>
                    <Plus className={'h-6 w-6 group-hover:text-primary'} />
                </div>
            </div>
        </div>
    )
}
