import apiRequest from '@/lib/apiClient.ts'

export type AiOperationsExecution = {
    executionDateTime: string
    tenantId: string
    providerType: string
    modelName: string
    pipelineId: string
    pipelineName: string
    pipelineVersion: string
    projectId: string
    projectName: string
    executionId: string
    executionSourceType: string
    executionUser?: string
    totalTokenAmountConsumed: number
    inputTokenAmountConsumed: number
    outputTokenAmountConsumed: number
    totalTokenCountConsumed: number
    inputTokenCountConsumed: number
    outputTokenCountConsumed: number
    balanceUsed: number
}

export interface ConsumptionFilterOptions {
    projectId?: string
    executionDate?: 'thisMonth' | 'lastMonth' | 'last90Days' | number
}

export const getAllExecutions = (): Promise<AiOperationsExecution[]> => {
    return apiRequest('/marketplace/v1/AIOperationExecutions', {
        queryParams: { Descending: 'true' },
    })
}

export const downloadOperationsCsv = (
    start: number = 0,
    end: number = Date.now()
): Promise<string> => {
    return apiRequest('/marketplace/v1/AIOperationExecutions/export/csv', {
        headers: {
            'Content-Type': 'text/csv',
        },
        queryParams: { start, end },
    })
}
