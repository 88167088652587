import { Connector } from '@/api/connectors.tsx'
import Search from '@/assets/icons/search.svg?react'
import { ConnectorCard } from '@/components/featuredConnectors/ConnectorCard'
import SelectProjectForm from '@/components/modelLibrary/selectProjectForm.tsx'
import { Dialog, DialogContent } from '@/components/ui/dialog.tsx'
import { Input } from '@/components/ui/input.tsx'
import { useRouter } from '@tanstack/react-router'
import { useEffect, useState } from 'react'

interface ConnectorsLibraryProps {
    connectors: Connector[]
}

export function ConnectorsLibrary({ connectors }: ConnectorsLibraryProps) {
    const router = useRouter()
    const [search, setSearch] = useState('')
    const [filteredConnectors, setFilteredConnectors] = useState<Connector[]>(connectors)
    const [addToProjectOpen, setAddToProjectOpen] = useState(false)
    const [selectedConnectorId, setSelectedConnectorId] = useState('')

    useEffect(() => {
        if (search !== '') {
            setFilteredConnectors(() =>
                connectors.filter(
                    (connector) =>
                        connector.name.toLowerCase().includes(search.toLowerCase()) ||
                        connector.classification.toLowerCase().includes(search.toLowerCase())
                )
            )
        } else {
            setFilteredConnectors(connectors)
        }
    }, [search, connectors])

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const onCardClick = (connectorId: string) => {
        setSelectedConnectorId(connectorId)
        setAddToProjectOpen(true)
    }

    const onAddToProject = (projectId: string) => {
        router.navigate({
            to: '/$projectId/datasets/new',
            params: {
                projectId,
            },
            search: {
                connectorId: selectedConnectorId,
            },
        })
    }

    return (
        <div className={'gap-6 mt-2 mx-1'}>
            <div>
                <div>
                    <div className={''}>
                        <div className="relative">
                            <Input
                                placeholder={'Search Data Sources'}
                                onChange={onSearchChange}
                                className={'px-4 py-2 pl-10 w-1/3'}
                            />
                            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                        </div>
                    </div>
                </div>
                <div
                    className={
                        'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-8'
                    }
                >
                    {filteredConnectors.map((connector) => (
                        <ConnectorCard
                            connector={connector}
                            onCardClick={() => onCardClick(connector.id)}
                        />
                    ))}
                </div>
            </div>
            <Dialog open={addToProjectOpen} onOpenChange={setAddToProjectOpen}>
                <DialogContent>
                    <SelectProjectForm onSelectProjectSubmit={onAddToProject} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
